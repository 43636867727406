<template>
  <div >
    <div v-if="ifPageShow" class="a_flex deviceScan" style="height: 90vh;flex-direction: column;">
      <div class="a_c deviceScan_li">
        <div class="deviceScan_li_left">
          设备名称：
        </div>
        <div class="deviceScan_li_input">
          <input v-model="mechineName" placeholder="请输入名称" type="text" />
        </div>
      </div>
      <div class="a_c deviceScan_li">
        <div class="deviceScan_li_left">
          手机号：
        </div>
        <div class="deviceScan_li_input">
          <input type="number" v-model="mobile" max='1' placeholder="请输入手机号码" />
        </div>
      </div>
      <button style="padding: 20px 30px;margin-top: 40px;" @click="TestDeviceQrCodeF">建立绑定</button>
    </div>
  </div>

</template>

<script>
import H5Report from '@/api/index'
import axios from 'axios'
export default {
  data () {
    return {
      ifPageShow:false,
      machinecode: '',
      mechineName: '',
      mobile: ''
    }
  },
  mounted () {
    this.machinecode = this.$query('machinecode')
    this.IsDeviceValid()
  },
  methods: {
    IsDeviceValid () {
      const that = this
      axios
        .post(
        // `https://healthingcoretest.giantgocloud.com/api/SceneCheckPage/GetPrecisePdfPageData`,
        `${H5Report}/boardApi/Tools/IsDeviceValid`, {
          machinecode: that.machinecode
        }
        ).then(res => {
          console.log('是否绑定返回值', res)
          if(res.data.data){
            that.ifPageShow=true
          }else{
            that.ifPageShow=false
            that.$toast(res.data.msg)
          }
        })
    },
    TestDeviceQrCodeF () {
      const that = this
      const number = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/
      if (!this.mechineName) {
        that.$toast('请输入设备名称')
      	return
      }
      if (!number.test(this.mobile)) {
        that.$toast('请输入正确的手机号')
      	return
      }
      axios
        .post(
        // `https://healthingcoretest.giantgocloud.com/api/SceneCheckPage/GetPrecisePdfPageData`,
        `${H5Report}/boardApi/Tools/TestDeviceQrCode`, {
          machinecode: that.machinecode,
          mechineName:that.mechineName,
          mobile:that.mobile,
        }
        ).then(res => {
          console.log('调取接口返回值', res)
          that.$toast(res.data.msg)
          // uni.showToast({
          //   icon: 'success',
          //   title: '保存成功'
          // })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .deviceScan{
    .deviceScan_li{
      margin-top:30px;
      .deviceScan_li_left{
        width:66px;
      }
      .deviceScan_li_input{

      }
    }
  }
</style>
